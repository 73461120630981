/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

input[type='text'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid rgb(58, 12, 207);
  border-radius: 20px;
}

#footercailogo {
  position: relative;
  left: px;
  width: 100%;
}
#footerlogoarea {
  width: 300px;
  height: 120px;
}

.bg {
  background-color: transparent;
}

.MuiTableRow-root:hover {
  background-color: inherit !important;
  transition: none !important;
}
